import React from 'react'
import styled from 'styled-components'
import { range } from 'd3-array'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
//import { wrangle32Data } from '../../utils/helpers'

const Container = styled.div`
  flex: 1;
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: 300px;
  height: 400px;
  margin: 3rem 0 7rem;
  display: flex;
`
const GridContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`
const GridLine = styled.div`
  border-bottom: 1px solid var(--lightGray);
  height: 2px;
  width: 100%;
`
const Flex = styled.div`
  display: flex;
  align-items: center;
`
const Tick = styled.div`
  font-size: 1rem;
  color: var(--gray);
  padding-right: 0.5rem;
  width: 2.5rem;
  text-align: right;
`
const BarContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 30px;
  height: calc(100% - 1.3rem);
  width: calc(100% - 20px);
  display: flex;
`
const BarCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin: 0 0 0 20px;
  max-width: 80px;
  width: 100%;
`
const Bar = styled.div`
  height: ${({ height }) => height};
  background-color: var(--brightBlue);
  color: var(--white);
  font-weight: bold;
  text-align: center;
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const BarLabelContainer = styled.div`
  position: absolute;
  height: 5rem;
  bottom: -6rem;
`
const BarLabel = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ weight }) => weight};
  color: var(--darkGray);
  text-align: center;
  line-height: 1.2;
  margin: 0 -0.5rem;

  ${Mq.md} {
    font-size: 1rem;
  }
`
const Line = styled.div`
  width: calc(100% - 0.5rem);
  height: ${({ height }) => height};
  border-top: 3px solid var(--baseBlue);
  position: absolute;
  left: 0.5rem;
  bottom: 0;
`
const Label = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  color: var(--baseBlue);
  width: 100%;
  padding-right: 0.5rem;
  text-align: right;
  position: absolute;
  top: ${({ position }) => position};
  line-height: 1.2;
  ${Mq.sm} {
    font-size: 0.9rem;
  }
`
const IG32Chart = ({ regions, title }) => {
  const { t } = useTranslation()
  const ticks = range(0, 11)
  //const formattedData = wrangle32Data(regions)
  const calcMaxWidth = (data) => {
    return Math.max(data.length * 100 + 100, 600) + 'px'
  }
  const baselineValue = regions[0].VALUE_NUM
  const BASELINE_YEAR = 2019
  return (
    <Container
      maxWidth={calcMaxWidth(regions)}
      role="img"
      alt={t('Bar chart showing UHC index of service coverage by year.')}
    >
      <GridContainer>
        {ticks.reverse().map((tick, i) => (
          <Flex key={tick}>
            <Tick>{tick * 10}</Tick> <GridLine />
          </Flex>
        ))}
        <BarContainer>
          {regions.map((d, i) => (
            <BarCol key={i}>
              <Bar height={`${d.VALUE_NUM}%`} key={`${d.VALUE_NUM}` + i}>
                {d.VALUE_NUM.toFixed()}
                <BarLabelContainer>
                  <BarLabel
                    weight={d.YEAR === BASELINE_YEAR ? 'bold' : 'normal'}
                  >
                    {d.YEAR === BASELINE_YEAR ? t('Baseline') : d.YEAR}
                  </BarLabel>
                  {/* <BarLabel weight={'normal'}>{d.YEAR}</BarLabel> */}
                  {/* {d.YEAR === 2019 && (
                    <BarLabel weight={'bold'}>{t('Baseline')}</BarLabel>
                  )} */}
                </BarLabelContainer>
              </Bar>
            </BarCol>
          ))}
          <Line height={`${baselineValue}%`}>
            <Label position={'-30px'}>{t('2030 target')}</Label>
            <Label position={'5px'}>
              {t('increase')} <br />
              {t('from baseline')}
            </Label>
          </Line>
        </BarContainer>
      </GridContainer>
    </Container>
  )
}

export default IG32Chart
